import React, { PureComponent } from 'react';
import { clone } from 'ramda';

import { connect } from '../../index';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, IShouldRenderShowAllButtonProps,
    ITransformToActiveFiltersProps,
    IClientSideFilterOfListDataProps,
    IRenderSearchContentProps, IRenderFilterContentProps,
} from '../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../routeKeys';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../models/general/list';
import Translate from '../../common/Translate';
import { DEFAULT_EXECUTED_INTERVENTIONS_FILTERS } from '../../../api/interventions/executedInterventions.api';
import ListWithSorting from '../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../common/input/TextInput';
import {
    getExecutedInterventionsAsyncInfo, getExecutedInterventions, getPrestationCodeQueryParam,
} from '../../../redux/intervention/executed/selectors';
import {
    IExecutedIntervention,
    IFetchExecutedInterventionsPayload,
} from '../../../models/interventions/interventions';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../utils/formatting/formatDate';
import { formatPersonName } from '../../../utils/formatting/formatPerson';
import { getRoutePayload } from '../../../redux/location/selectors';
import PageHeader from '../../appShell/PageHeader';
import ShowIfAllowed from '../../auth/ShowIfAllowed';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import { INTERVENTION_ILLUSTRATIONS, PRESTATION_CODE } from '../../../config/navigation/interventions.config';
import { triggerInterventionRequestWizard } from '../../../redux/intervention/requestIntervention/actions';
import { createGenericActiveFilters } from '../../common/widget/MasterWithDetail/Master/ActiveFilters';
import StartEndDateFilter from '../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../common/input/StartEndDateFilter/startEndDateSchema';
import { IStartEndDateFilterValues } from '../../../models/ui/form';
import { ITranslator } from '../../../models/general/i18n';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../common/input/CheckboxesOrTypeaheadFilter';
import { separateStringList } from '../../../utils/core/string/separatedStringList';
import { SVG_GROUP_NAME } from '../../../models/general/lazyLoadSvg';

const BASE_NAME = 'executed-interventions';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;
const CLASS_NAME = 'ExecutedInterventions';

interface IColumnNames {
    date: string;
    dateSort: string;
    reference: string;
    company: string;
    executedBy: string;
    description: string;
    type: string;
    typeId: string;
}

type ColumnWidth<IColumnNames> = {
    [key in keyof IColumnNames]: number;
};

interface IColumnWidths {
    typeVisible: ColumnWidth<IColumnNames>;
    typeHidden: ColumnWidth<IColumnNames>;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
    typeIds: string;
    description: string;
    company: string;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'date',
    sortOrder: SortOrder.Descending,
};

const COLUMN_WIDTHS: IColumnWidths = {
    typeVisible: {
        date: 15,
        dateSort: null,
        reference: 14,
        company: 16,
        executedBy: 16,
        description: 23,
        type: 16,
        typeId: null,
    },
    typeHidden: {
        date: 15,
        dateSort: null,
        reference: 15,
        company: 28,
        executedBy: 14,
        description: 28,
        type: null,
        typeId: null,
    },
};

const COLUMNS: ListColumns<IColumnNames> = {
    date: {
        label: <Translate msg="interventions.executed.columns.date" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.dateSort,
        percentWidth: COLUMN_WIDTHS.typeVisible.date,
    },
    dateSort: {
        hide: true,
        percentWidth: COLUMN_WIDTHS.typeVisible.dateSort,
    },
    company: {
        label: <Translate msg="interventions.executed.columns.company" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: COLUMN_WIDTHS.typeVisible.company,
    },
    type: {
        label: <Translate msg="interventions.executed.columns.type" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: COLUMN_WIDTHS.typeVisible.type,
    },
    description: {
        label: <Translate msg="interventions.executed.columns.description" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: COLUMN_WIDTHS.typeVisible.description,
    },
    reference: {
        label: <Translate msg="interventions.executed.columns.reference" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: COLUMN_WIDTHS.typeVisible.reference,
    },
    executedBy: {
        label: <Translate msg="interventions.executed.columns.executed_by" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: COLUMN_WIDTHS.typeVisible.executedBy,
    },
    typeId: {
        hide: true,
        percentWidth: COLUMN_WIDTHS.typeVisible.typeId,
    },
};

interface IPrivateProps {
    currentRoutePayload: IFetchExecutedInterventionsPayload;
    goToRequestIntervention: () => void;
}

function ExecutedInterventionsComp(props: IPrivateProps) {
    const {
        currentRoutePayload,
        goToRequestIntervention,
    } = props;

    const buttonElement = (
        <ShowIfAllowed routeKey={ROUTE_KEYS.R_INTERVENTION_REQUEST_NEW}>
            <Button
                id="page-header-plus-button"
                typeName="secondary"
                onClick={goToRequestIntervention}
            >
                <Icon typeName="plus" />
                <Translate msg="interventions.executed.action.request" />
            </Button>
        </ShowIfAllowed>
    );

    const onAllInterventionsPage = currentRoutePayload.prestationCode === 'all';

    // Only show Type column on ALL intervention type page
    COLUMNS.type.hide = !onAllInterventionsPage;

    // Set column widths based on intervention type (all interventions shows less columns)
    const columnWidths = onAllInterventionsPage ? COLUMN_WIDTHS.typeVisible : COLUMN_WIDTHS.typeHidden;
    Object.keys(COLUMNS).map((columnName) => {
        COLUMNS[columnName].percentWidth = columnWidths[columnName];
    });

    const lazyLoadSvgId = INTERVENTION_ILLUSTRATIONS[currentRoutePayload.prestationCode];

    return (
        <div className={`${CLASS_NAME}`}>
            <PageHeader
                breadcrumbs={true}
                title={`interventions.overview.main.${currentRoutePayload.prestationCode}_interventions`}
                lazyLoadSvg={lazyLoadSvgId && {
                    id: lazyLoadSvgId,
                    group: SVG_GROUP_NAME.INTERVENTIONS,
                }}
                button={buttonElement}
                type="grey"
            />
            <MasterWithDetail
                baseName={BASE_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    routeKey: ROUTE_KEYS.R_EXECUTED_INTERVENTIONS,
                    routePayload: currentRoutePayload,
                    asyncInfoSelector: getExecutedInterventionsAsyncInfo,
                    dataSelector: getExecutedInterventions,
                    transformData: mapExecutedInterventionsToListItems,
                    transformFilterValuesToActiveFilters,
                    renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <InterventionList {...renderProps} />,
                    clientSideSearchOfListData: {
                        searchFilterName: 'search',
                        columnsConfig: COLUMNS,
                    },
                    clientSideFilterOfListData,
                    filterValidationSchema: startEndDateSchema,
                }}
                headerConfig={{
                    renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                        <SearchContent {...renderProps} />,
                    renderFilterContent:
                        (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                            <FilterContent {...renderProps} />,
                    exportButton: {
                        baseFilename: `${currentRoutePayload.prestationCode}-interventions`,
                    },
                }}
                footerConfig={{
                    shouldRenderShowAllButton,
                }}
            />
        </div>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            currentRoutePayload: getRoutePayload<IFetchExecutedInterventionsPayload>(state),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            goToRequestIntervention: () => {
                const prestationCode = getPrestationCodeQueryParam(getState());
                dispatch(triggerInterventionRequestWizard(prestationCode as PRESTATION_CODE));
            },
        };
    },
})(ExecutedInterventionsComp);

function mapExecutedInterventionsToListItems(
    masterData: IExecutedIntervention[],
    translator: ITranslator,
): ListItem<IColumnNames>[] {
    return masterData
        .map((intervention) => ({
            id: intervention.id,
            columns: {
                date: formatDateInLongFormat(intervention.date),
                dateSort: intervention.date,
                reference: intervention.reference,
                executedBy: formatPersonName(
                    {
                        firstName: intervention.executedByFirstName,
                        name: intervention.executedByLastName,
                    },
                ),
                company: intervention.company.name,
                description: intervention.description,
                type: translator(`interventions.executed.types.${(intervention.prestationCode).toLowerCase()}`),
                typeId: intervention.prestationCode,
            },
        }));
}

class InterventionList extends PureComponent <IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props) {
        super(props);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            filterValues,
            footer,
        } = this.props;

        return (
            <ListWithSorting
                columns={this.columns}
                items={clientSideFilteredlistItems}
                name={BASE_NAME}
                errorMessage={masterAsyncInfo.error &&
                    <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                selectedItemIds={selectedItemId ? [selectedItemId] : []}
                initialSort={INITIAL_SORT}
                maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                footer={footer}
            />
        );
    }
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.typeIds)) {
                return true;
            }

            const typeIds = separateStringList(filterValues.typeIds);
            return typeIds.includes(listItem.columns.typeId.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.description)) {
                return true;
            }

            const references = separateStringList(filterValues.description);
            return references.includes(listItem.columns.description.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.company)) {
                return true;
            }

            const references = separateStringList(filterValues.company);
            return references.includes(listItem.columns.company.toString());
        });
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.executed.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.executed.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleTypes = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'typeId',
        'type',
    );

    const possibleCompanies = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'company',
        'company',
    );

    const possibleDescriptions = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'description',
        'description',
    );

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix="interventions.executed.filter"
                formRenderProps={formRenderProps}
            />
            {(possibleCompanies && possibleCompanies.length > 0) && (
                <CheckboxesOrTypeaheadFilter
                    filterName="company"
                    labelTranslationKey="interventions.executed.filter.company"
                    possibleFilterItems={possibleCompanies}
                    actualFilterValue={formRenderProps.values.company}
                    onChange={(newFilterValue) => formRenderProps.setFieldValue('company', newFilterValue)}
                />
            )}
            {(possibleTypes && possibleTypes.length > 1) && (
                <CheckboxesOrTypeaheadFilter
                    filterName="typeId"
                    labelTranslationKey="interventions.executed.filter.type"
                    possibleFilterItems={possibleTypes}
                    actualFilterValue={formRenderProps.values.typeIds}
                    onChange={(newFilterValue) => formRenderProps.setFieldValue('typeIds', newFilterValue)}
                />
            )}
            {(possibleDescriptions && possibleDescriptions.length > 0) && (
                <CheckboxesOrTypeaheadFilter
                    filterName="description"
                    labelTranslationKey="interventions.executed.filter.description"
                    possibleFilterItems={possibleDescriptions}
                    actualFilterValue={formRenderProps.values.description}
                    onChange={(newFilterValue) => formRenderProps.setFieldValue('description', newFilterValue)}
                />
            )}
        </div>
    );
}

function getDefaultQueryParams({ isShowAll }: {isShowAll: boolean}) {
    return isShowAll ? {
        ...DEFAULT_EXECUTED_INTERVENTIONS_FILTERS,
        isShowAll,
    } : DEFAULT_EXECUTED_INTERVENTIONS_FILTERS;
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.executed.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_EXECUTED_INTERVENTIONS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_EXECUTED_INTERVENTIONS_FILTERS.startDate,
            },
            company: {
                show: true,
            },
            typeIds: {
                show: true,
                translationKeySuffixOverride: 'type',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'type',
                        searchColumnName: 'typeId',
                    },
                },
            },
            description: {
                show: true,
            },

        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}
