import React, { PureComponent } from 'react';
import './cancel-examination.scss';
import Loader from '../../../common/waiting/Loader';
import GoBack from '../../../common/navigation/GoBack';
import Translate from '../../../common/Translate';
import FormError from '../../../common/forms/FormError';
import Button from '../../../common/buttons/Button';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../models/general/redux';
import { ITranslator } from '../../../../models/general/i18n';
import connect from '../../../../utils/libs/redux/connect';
import { getTranslatorDeprecated } from '../../../../redux/i18n/selectors';
import { getRemoveTimeslotAsyncInfo } from '../../../../redux/medicalExamination/selectors';

const CLASS_NAME = 'CancelExamination';

interface ICancelExaminationProps {
    onGoBack?: () => void;
    onCancel: () => void;
    onCancelCompleted?: () => void;
    isReplan?: boolean;
}

interface IPrivateProps {
    removeTimeslotAsyncInfo: IAsyncFieldInfo;
    translator: ITranslator;
}

class CancelExamination extends PureComponent<IPrivateProps & ICancelExaminationProps> {
    constructor(props: IPrivateProps & ICancelExaminationProps) {
        super(props);
    }

    public render() {
        const { removeTimeslotAsyncInfo, onCancel, onGoBack, isReplan } = this.props;
        return (
            <div className={CLASS_NAME}>
                <Loader show={removeTimeslotAsyncInfo.status}>
                    {typeof onGoBack === 'function' &&
                        <GoBack id="edit-appointment-cancel-go-back" onClick={onGoBack} />}
                    <h4>
                        <Translate // eslint-disable-next-line max-len
                            msg={`interventions.medical_examinations.edit_appointment.cancel.title${isReplan ? '_replan' : ''}`}
                        />
                    </h4>
                    <Translate msg="interventions.medical_examinations.edit_appointment.cancel.text" />
                    <FormError error={removeTimeslotAsyncInfo.error} />
                    <div className={`${CLASS_NAME}__submit-button`}>
                    {!!removeTimeslotAsyncInfo.error ?
                        <Button
                            id="not-editable-appointment-cancel-submit-button"
                            typeName="secondary"
                            onClick={() => onGoBack()}
                        >
                            <Translate
                                msg="interventions.medical_examinations.edit_appointment.cancel.ok"
                            />
                        </Button>
                    :
                        <Button
                            id="edit-appointment-cancel-submit-button"
                            typeName="secondary"
                            onClick={() => onCancel()}
                        >
                            <Translate
                                msg="interventions.medical_examinations.edit_appointment.cancel.submit"
                            />
                        </Button>
                    }
                    </div>
                </Loader>
            </div>
        );
    }

    public componentDidUpdate(prevProps: IPrivateProps & ICancelExaminationProps) {
        if (typeof this.props.onCancelCompleted !== 'function') {
            return;
        }
        if (
            (prevProps.removeTimeslotAsyncInfo.status === AsyncStatus.Busy &&
                this.props.removeTimeslotAsyncInfo.status === AsyncStatus.Success)
        ) {
            this.props.onCancelCompleted();
        }
    }
}

export default connect<IPrivateProps, ICancelExaminationProps>({
    stateProps: (state) => {
        return {
            removeTimeslotAsyncInfo: getRemoveTimeslotAsyncInfo(state),
            translator: getTranslatorDeprecated(state),
        };
    },
})(CancelExamination);
