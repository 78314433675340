import React, { PureComponent, MouseEvent } from 'react';
import { clone } from 'ramda';
import { connect } from '../..';
import MasterWithDetail from '../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps, IShouldRenderShowAllButtonProps,
    ITransformToActiveFiltersProps,
    IRenderSearchContentProps, IRenderFilterContentProps,
    IClientSideFilterOfListDataProps,
} from '../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../routeKeys';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../models/general/list';
import Translate from '../../common/Translate';
import ListWithSorting from '../../common/list/ListWithSorting';
import ErrorPlaceholder from '../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../common/input/TextInput';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../utils/formatting/formatDate';
import PageHeader from '../../appShell/PageHeader';
import { IWorkAccident } from '../../../models/interventions/workAccidents';
import headerIllu from '../../assets/img/illustrations/illu_arbeidsongevallen.svg';
import { DEFAULT_WORK_ACCIDENTS_FILTERS } from '../../../api/interventions/workAccidents.api';
import {
    getWorkAccidentsAysncInfo, getWorkAccidents,
    getFilesForWorkAccidentAsyncInfo,
} from '../../../redux/intervention/workAccidents/selectors';
import ListItemActions from '../../common/list/ListItemActions';
import Button from '../../common/buttons/Button';
import Icon from '../../common/icons/Icon';
import { fetchWorkAccidentsFilesActions } from '../../../redux/intervention/workAccidents/actions';
import { AsyncStatus } from '../../../models/general/redux';
import NoDocumentsFoundErrorDialog from '../../common/modals/NoDocumentsFoundErrorDialog';
import Loader from '../../common/waiting/Loader';
import { createGenericActiveFilters } from '../../common/widget/MasterWithDetail/Master/ActiveFilters';
import StartEndDateFilter from '../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../common/input/StartEndDateFilter/startEndDateSchema';
import { IStartEndDateFilterValues } from '../../../models/ui/form';
// eslint-disable-next-line max-len
import getUniqueTypeaheadFilterValuesFromListItems from '../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../common/input/CheckboxesOrTypeaheadFilter';
import { separateStringList } from '../../../utils/core/string/separatedStringList';

const BASE_NAME = 'executed-interventions';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;
const CLASS_NAME = 'ExecutedInterventions';

interface IColumnNames {
    company: string;
    accidentDate: string;
    accidentDateSort: string;
    userName: string;
    internalReference: string;
    actions: string;
}

interface IFilterValues extends IStartEndDateFilterValues {
    company: string;
    search: string;
    isShowAll: boolean;
}

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'accidentDate',
    sortOrder: SortOrder.Descending,
};

const COLUMNS: ListColumns<IColumnNames> = {
    accidentDate: {
        label: <Translate msg="interventions.work_accidents.columns.date" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.accidentDateSort,
        percentWidth: 20,
    },
    accidentDateSort: {
        hide: true,
        percentWidth: null,
    },
    company: {
        label: <Translate msg="interventions.work_accidents.columns.company" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
    userName: {
        label: <Translate msg="interventions.work_accidents.columns.username" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    internalReference: {
        label: <Translate msg="interventions.work_accidents.columns.reference" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    actions: {
        sortable: false,
        percentWidth: 10,
    },
};

type TWorkAccidentsListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

interface IPrivateProps {
    isDownloadingDocument: boolean;
    onDownloadClick: (id: string) => void;
}

function WorkAccidentsComp(props: IPrivateProps) {
    return (
        <div className={`${CLASS_NAME}`}>
            <PageHeader
                breadcrumbs={true}
                title="interventions.work_accidents.title"
                svg={headerIllu}
                type="green"
                text="interventions.work_accidents.subtitle"
                isTextRaw
            />
            <MasterWithDetail
                baseName={BASE_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    routeKey: ROUTE_KEYS.R_WORK_ACCIDENTS,
                    asyncInfoSelector: getWorkAccidentsAysncInfo,
                    dataSelector: getWorkAccidents,
                    transformData: mapWorkAccidentsToListItems,
                    transformFilterValuesToActiveFilters,
                    renderContent: (renderProps: TWorkAccidentsListProps) =>
                        <WorkAccidentsList {...renderProps} {...props} />,
                    clientSideSearchOfListData: {
                        searchFilterName: 'search',
                        columnsConfig: COLUMNS,
                    },
                    clientSideFilterOfListData,
                    filterValidationSchema: startEndDateSchema,
                }}
                headerConfig={{
                    renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                        <SearchContent {...renderProps} />,
                    renderFilterContent:
                        (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                            <FilterContent {...renderProps} />,
                    exportButton: {
                        baseFilename: 'work-accidents',
                    },
                }}
                footerConfig={{
                    shouldRenderShowAllButton,
                }}
            />
        </div>
    );
}

function mapWorkAccidentsToListItems(masterData: IWorkAccident[]): ListItem<IColumnNames>[] {
    return masterData
        .map((workAccident) => ({
            id: workAccident.id,
            columns: {
                accidentDate: formatDateInLongFormat(workAccident.accidentDate),
                accidentDateSort: workAccident.accidentDate,
                userName: workAccident.userName,
                company: workAccident.company.name,
                internalReference: workAccident.internalReference,
                actions: null,
            },
        }));
}

class WorkAccidentsList extends PureComponent <TWorkAccidentsListProps & IPrivateProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props) {
        super(props);

        this.onDownloadClick = this.onDownloadClick.bind(this);

        this.columns.actions.render = (listItem: ListItem<IColumnNames>, index) => {
            return (
                <ListItemActions>
                    <Button
                        id="download-file-button"
                        onClick={(e) => this.onDownloadClick(e, listItem)}
                    >
                        <span><Translate msg="interventions.work_accidents.actions.download" /></span>
                        <Icon circle typeName="download-file" />
                    </Button>
                </ListItemActions>
            );
        };
    }

    private onDownloadClick(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        const {
            onDownloadClick,
        } = this.props;

        onDownloadClick(listItem.id as string);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedItemId,
            filterValues,
            footer,
            isDownloadingDocument,
        } = this.props;

        return (
            <>
                <ListWithSorting
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    initialSort={INITIAL_SORT}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                />
                <NoDocumentsFoundErrorDialog
                    asyncInfoSelector={getFilesForWorkAccidentAsyncInfo}
                />
                <Loader show={isDownloadingDocument} />
            </>
        );
    }
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.work_accidents.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.work_accidents.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleCompanies = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'company',
        'company',
    );

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix="interventions.work_accidents.filter"
                formRenderProps={formRenderProps}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="company"
                labelTranslationKey="interventions.work_accidents.filter.company"
                possibleFilterItems={possibleCompanies}
                actualFilterValue={formRenderProps.values.company}
                onChange={(newFilterValue) => formRenderProps.setFieldValue('company', newFilterValue)}
            />
        </div>
    );
}

function getDefaultQueryParams({ isShowAll }: {isShowAll: boolean}) {
    return isShowAll ? {
        ...DEFAULT_WORK_ACCIDENTS_FILTERS,
        isShowAll,
    } : DEFAULT_WORK_ACCIDENTS_FILTERS;
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.company)) {
                return true;
            }

            const references = separateStringList(filterValues.company);
            return references.includes(listItem.columns.company.toString());
        });
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.work_accidents.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            company: {
                show: true,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_WORK_ACCIDENTS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_WORK_ACCIDENTS_FILTERS.startDate,
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const downloadAsyncInfo = getFilesForWorkAccidentAsyncInfo(state);
        const isDownloadingDocument = downloadAsyncInfo.status === AsyncStatus.Busy;

        return {
            isDownloadingDocument,
        };
    },
    dispatchProps: (dispatch) => ({
        onDownloadClick: (id: string) => {
            dispatch(fetchWorkAccidentsFilesActions.trigger({
                id,
            }));
        },
    }),
})(WorkAccidentsComp);
