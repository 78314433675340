import { object } from 'yup';
import string from '../../../../../../../utils/libs/yup/string';
import { ShapeOf } from '../../../../../../../models/ui/form';
import phoneNumber from '../../../../../../../utils/libs/yup/phoneNumber';
import number from '../../../../../../../utils/libs/yup/number';

export interface FormValues {
    planningPreference: string;
    preferredTime: string;
    medicalCenterCode: string;
    medicalCenterName: string;
    contactPhoneNumber: string;
    resumptionDate: string;
    weekDays: {
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
    };
}

export const fields: ShapeOf<FormValues> = {
    planningPreference: 'planningPreference',
    preferredTime: 'preferredTime',
    medicalCenterCode: 'medicalCenterCode',
    medicalCenterName: 'medicalCenterName',
    contactPhoneNumber: 'contactPhoneNumber',
    resumptionDate: 'resumptionDate',
    weekDays: 'weekDays',

};

export const schema = object({
    planningPreference: string(),
    preferredTime: string(),
    medicalCenterId: number(),
    medicalCenterCode: string(),
    medicalCenterName: string(),
    contactPhoneNumber: phoneNumber(),
    resumptionDate: string(),
    weekDays: object(),
});
