import React, { PureComponent } from 'react';

import './edit-appointment-dialog.scss';
import Dialog from '../../../../common/modals/Dialog';
import Icon from '../../../../common/icons/Icon';
import Translate from '../../../../common/Translate';
import Button from '../../../../common/buttons/Button';
import EmployeeTypeahead from '../../../../administration/employee/EmployeeTypeahead';
import { connect } from '../../../../index';
import { getTranslatorDeprecated } from '../../../../../redux/i18n/selectors';
import { ITranslator } from '../../../../../models/general/i18n';
import {
    PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE,
    PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID,
} from '../../../../../models/interventions/medicalExaminations';
import { IPlannedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/planned';
import {
    // triggerReplaceEmployeeUpdateTimeSlot,
    removeTimeSlot,
    skipToPlanMedicalExaminationWizardStepActions,
    validateEmployeeToPlanActions,
} from '../../../../../redux/medicalExamination/actions';
import { IAsyncFieldInfo, AsyncStatus } from '../../../../../models/general/redux';
import {
    getUpdateTimeslotAsyncInfo,
    getRemoveTimeslotAsyncInfo,
    getLastUpdatedTimeslotId,
    getValidateEmployeeToPlanAsyncInfo,
    getExaminationReasonByCode,
} from '../../../../../redux/medicalExamination/selectors';
import { IEmployee } from '../../../../../models/admin/employee';
import Loader from '../../../../common/waiting/Loader';
import FormError from '../../../../common/forms/FormError';
import { ITraceableApiError } from '../../../../../models/general/error';
import { clearErrors } from '../../../../../utils/libs/redux/generic/actions';
import GoBack from '../../../../common/navigation/GoBack';
import CancelExamination from '../../../shared/CancelExamination';
import isCancelExaminationAllowed
    from '../../../../../utils/interventions/medicalExaminations/isCancelExaminationAllowed';
import isChangeExaminationEmployeeAllowed
    from '../../../../../utils/interventions/medicalExaminations/isChangeExaminationEmployeeAllowed';
import { NR_OF_HOURS_BEFORE_EXAM_REPLAN_ALLOWED } from '../../../../../config/medicalExamination.config';
import TinyLoader from '../../../../common/waiting/TinyLoader';
import { getCaseManagerAsyncInfo, getCaseManager } from '../../../../../redux/contact/selectors';
import { ICaseManager } from '../../../../../models/contact/caseManager';
import ContactInformation from '../../../../contact/shared/ContactInformation';
// eslint-disable-next-line max-len
import ValidateEmployeeToPlanDialog from '../../../PlanMedicalExamination/PlanMedicalExaminationWizard/shared/ValidateEmployeeToPlanDialog';

// Remove this const to re-enable change employee option
const TEMPORARILY_DISABLE_CHANGE_EMPLOYEE = true;

interface IPrivateProps {
    translator: ITranslator;
    plannedMedicalExamination: IPlannedMedicalExamination;
    updateTimeslotReplaceEmployee: (employee: IEmployee, plannedMedicalExamination: IPlannedMedicalExamination) => void;
    cancelTimeSlot: (plannedMedicalExamination: IPlannedMedicalExamination) => void;
    updateTimeslotAsyncInfo: IAsyncFieldInfo;
    removeTimeslotAsyncInfo: IAsyncFieldInfo;
    clearError: (error: ITraceableApiError) => void;
    triggerReplanWithSelectedExamination: (
        plannedMedicalExamination: IPlannedMedicalExamination
    ) => void;
    navigateToConvocationStepOfPlanning: (
        newEmployee: IEmployee,
        plannedMedicalExamination: IPlannedMedicalExamination
    ) => void;
    caseManager: ICaseManager;
    validateSelectedEmployee: (
        employeeToPlan: IEmployee,
        onValidationPassed: () => void,
        plannedMedicalExamination: IPlannedMedicalExamination
    ) => void;
    validateEmployeeToPlanAsyncInfo: IAsyncFieldInfo;
}

interface IEditAppointmentDialogProps {
    show: boolean;
    onCloseIntent: () => void;
    plannedMedicalExamination: IPlannedMedicalExamination;
}

interface IComponentState {
    isChangeEmployeeOpen: boolean;
    isCancelOpen: boolean;
    selectedEmployee: IEmployee;
    replanSelected: boolean;
}

const CLASS_NAME = 'EditAppointmentDialog';
const INITIAL_STATE: IComponentState = {
    isCancelOpen: false,
    isChangeEmployeeOpen: false,
    selectedEmployee: null,
    replanSelected: false,
};

class EditAppointmentDialog extends PureComponent<IPrivateProps & IEditAppointmentDialogProps, IComponentState> {

    constructor(props: IPrivateProps & IEditAppointmentDialogProps) {
        super(props);

        this.state = INITIAL_STATE;

        this.onClose = this.onClose.bind(this);

        this.onCancelHandler = this.onCancelHandler.bind(this);
        this.onChangeEmployeeHandler = this.onChangeEmployeeHandler.bind(this);
        this.onReplanHandler = this.onReplanHandler.bind(this);
        this.onGoBackHandler = this.onGoBackHandler.bind(this);
        this.onCancelAppointmentHandler = this.onCancelAppointmentHandler.bind(this);
        this.onUpdateTimeslotReplaceEmployeeHandler = this.onUpdateTimeslotReplaceEmployeeHandler.bind(this);
        this.onCancelAndReplanAppointmentHandler = this.onCancelAndReplanAppointmentHandler.bind(this);

        this.isCancelOrReplanAllowed = this.isCancelOrReplanAllowed.bind(this);
        this.isChangeEmployeeAllowed = this.isChangeEmployeeAllowed.bind(this);
    }

    public render() {
        const { show } = this.props;

        return (
            <>
                <Dialog show={show} onCloseIntent={this.onClose}>
                    <div className={CLASS_NAME}>
                        {this.renderContent()}
                    </div>
                </Dialog>
                <ValidateEmployeeToPlanDialog onConfirm={this.onUpdateTimeslotReplaceEmployeeHandler} />
            </>
        );
    }

    public componentDidMount() {
        const { clearError, updateTimeslotAsyncInfo, removeTimeslotAsyncInfo } = this.props;
        clearError(updateTimeslotAsyncInfo.error);
        clearError(removeTimeslotAsyncInfo.error);
    }

    public componentDidUpdate(prevProps: IPrivateProps & IEditAppointmentDialogProps) {
        if (
            prevProps.removeTimeslotAsyncInfo.status === AsyncStatus.Busy &&
            this.props.removeTimeslotAsyncInfo.status === AsyncStatus.Success
        ) {
            this.onClose();
        }

        if (
            prevProps.updateTimeslotAsyncInfo.status === AsyncStatus.Busy &&
            this.props.updateTimeslotAsyncInfo.status === AsyncStatus.Success &&
            this.props.plannedMedicalExamination
        ) {
            this.props.navigateToConvocationStepOfPlanning(
                this.state.selectedEmployee,
                this.props.plannedMedicalExamination,
            );
        }

        if (!prevProps.show && this.props.show) {
            this.setState(INITIAL_STATE);
        }
    }

    private renderContent() {
        const {
            translator,
            plannedMedicalExamination,
            updateTimeslotAsyncInfo,
            caseManager,
            validateSelectedEmployee,
            validateEmployeeToPlanAsyncInfo,
        } = this.props;
        const {
            isCancelOpen, isChangeEmployeeOpen, selectedEmployee, replanSelected,
        } = this.state;

        if (isCancelOpen) {
            return (
                <CancelExamination
                    onGoBack={this.onGoBackHandler}
                    onCancel={replanSelected ?
                        this.onCancelAndReplanAppointmentHandler :
                        this.onCancelAppointmentHandler}
                    isReplan={replanSelected}
                />
            );
        }

        if (isChangeEmployeeOpen) {
            const showLoader =
                validateEmployeeToPlanAsyncInfo.status === AsyncStatus.Busy ||
                updateTimeslotAsyncInfo.status === AsyncStatus.Busy;

            return (
                <>
                    <Loader show={showLoader} />
                    <GoBack id="edit-appointment-change-employee-go-back" onClick={this.onGoBackHandler} />
                    <h4>
                        <Translate msg="interventions.medical_examinations.edit_appointment.change_employee.title" />
                    </h4>
                    <span>
                        <Translate msg="interventions.medical_examinations.edit_appointment.change_employee.text" />
                    </span>
                    <EmployeeTypeahead
                        id="edit-appointment-employee"
                        name="employee"
                        value={selectedEmployee && selectedEmployee.id}
                        onItemSelected={(value, employee) => this.setState({ selectedEmployee: employee })}
                        placeholder={
                            // eslint-disable-next-line max-len
                            translator('interventions.medical_examinations.edit_appointment.change_employee.employee_placeholder')
                        }
                        employeeIdsToExcludeFromData={
                            plannedMedicalExamination && [plannedMedicalExamination.employee.employment.id]
                        }
                    />
                    <FormError error={updateTimeslotAsyncInfo.error} />
                    <FormError error={validateEmployeeToPlanAsyncInfo.error} />
                    <div className={`${CLASS_NAME}__submit-button`}>
                        <Button
                            id="edit-appointment-change-employee-submit-button"
                            typeName="secondary"
                            onClick={
                                () => validateSelectedEmployee(
                                    selectedEmployee,
                                    this.onUpdateTimeslotReplaceEmployeeHandler,
                                    plannedMedicalExamination,
                                )
                            }
                            disabled={!selectedEmployee}
                        >
                            <Translate
                                msg="interventions.medical_examinations.edit_appointment.change_employee.submit"
                            />
                        </Button>
                    </div>
                </>
            );
        }

        const isCancelOrReplanAllowed = this.isCancelOrReplanAllowed();
        const isChangeEmployeeAllowed = this.isChangeEmployeeAllowed();
        const isNotEditable = !!plannedMedicalExamination && !plannedMedicalExamination.isEditable;

        return (
            <>
                <Icon typeName="warning" circle />
                {isNotEditable ? (
                    <h3>
                        <Translate msg="interventions.medical_examinations.edit_appointment.title_not_editable" />
                    </h3>
                )
                :
                (
                    <h3>
                        <Translate msg="interventions.medical_examinations.edit_appointment.title" />
                    </h3>
                )}
                {isNotEditable ? (
                    <>
                        <p>
                            <Translate
                                msg="interventions.medical_examinations.edit_appointment.text.is_not_editable"
                            />
                        </p>
                        <TinyLoader asyncInfoSelector={getCaseManagerAsyncInfo}>
                            <ContactInformation contact={caseManager} />
                        </TinyLoader>
                    </>
                ) :
                ((!TEMPORARILY_DISABLE_CHANGE_EMPLOYEE && !isChangeEmployeeAllowed) || !isCancelOrReplanAllowed) && (
                    <>
                        <p>
                            <Translate
                                msg="interventions.medical_examinations.edit_appointment.text.warning"
                                placeholders={{
                                    hours: (!TEMPORARILY_DISABLE_CHANGE_EMPLOYEE && !isChangeEmployeeAllowed) ?
                                        NR_OF_HOURS_BEFORE_EXAM_REPLAN_ALLOWED.CHANGE_EMPLOYEE :
                                        NR_OF_HOURS_BEFORE_EXAM_REPLAN_ALLOWED.CANCEL,
                                }}
                            />
                        </p>
                        <TinyLoader asyncInfoSelector={getCaseManagerAsyncInfo}>
                            <ContactInformation contact={caseManager} />
                        </TinyLoader>
                    </>
                )}
                {!TEMPORARILY_DISABLE_CHANGE_EMPLOYEE && isChangeEmployeeAllowed && !isCancelOrReplanAllowed && (
                    <p>
                        <Translate
                            msg="interventions.medical_examinations.edit_appointment.text.warning_prevent_cost"
                        />
                    </p>
                )}
                <div className={`${CLASS_NAME}__actions`}>
                    {isChangeEmployeeAllowed && !TEMPORARILY_DISABLE_CHANGE_EMPLOYEE && (
                        <Button
                            id="edit-appointment-change-employee-button"
                            typeName="secondary"
                            onClick={this.onChangeEmployeeHandler}
                        >
                            <Translate
                                msg="interventions.medical_examinations.edit_appointment.actions.change_employee"
                            />
                        </Button>
                    )}
                    {isCancelOrReplanAllowed && (
                        <>
                            <Button
                               id="edit-appointment-cancel-replan-button"
                               typeName="secondary"
                               onClick={this.onReplanHandler}
                            >
                               <Translate
                                   msg="interventions.medical_examinations.edit_appointment.actions.cancel_and_replan"
                               />
                            </Button>
                            <Button
                                id="edit-appointment-cancel-button"
                                typeName="secondary"
                                outline={true}
                                onClick={this.onCancelHandler}
                            >
                                {/*eslint-disable-next-line max-len*/}
                                <Translate msg="interventions.medical_examinations.edit_appointment.actions.cancel" />
                            </Button>
                        </>
                    )}

                </div>
            </>
        );
    }

    private isCancelOrReplanAllowed() {
        const { plannedMedicalExamination } = this.props;

        if (plannedMedicalExamination && !plannedMedicalExamination.isEditable){
            return false;
        }

        return plannedMedicalExamination && isCancelExaminationAllowed(plannedMedicalExamination.start);
    }

    private isChangeEmployeeAllowed() {
        const { plannedMedicalExamination } = this.props;

        return plannedMedicalExamination && isChangeExaminationEmployeeAllowed(plannedMedicalExamination.start);
    }

    private onChangeEmployeeHandler() {
        if (this.isChangeEmployeeAllowed()) {
            this.setState({ isChangeEmployeeOpen: true });
        }
    }

    private onReplanHandler() {
        if (this.isCancelOrReplanAllowed()) {
            this.setState({ isCancelOpen: true, replanSelected: true });
        }
    }

    private onCancelHandler() {
        if (this.isCancelOrReplanAllowed()) {
            this.setState({ isCancelOpen: true });
        }
    }

    private onGoBackHandler() {
        this.setState({ isCancelOpen: false, isChangeEmployeeOpen: false, replanSelected: false });
    }

    private onClose() {
        const { clearError, onCloseIntent, updateTimeslotAsyncInfo, removeTimeslotAsyncInfo } = this.props;
        clearError(updateTimeslotAsyncInfo.error);
        clearError(removeTimeslotAsyncInfo.error);
        onCloseIntent();
    }

    private onCancelAndReplanAppointmentHandler() {
        const { triggerReplanWithSelectedExamination, plannedMedicalExamination } = this.props;

        if (this.isCancelOrReplanAllowed()) {
            triggerReplanWithSelectedExamination(plannedMedicalExamination);
        }
    }

    private onCancelAppointmentHandler() {
        const { cancelTimeSlot, plannedMedicalExamination } = this.props;

        if (this.isCancelOrReplanAllowed()) {
            cancelTimeSlot(plannedMedicalExamination);
        }
    }

    private onUpdateTimeslotReplaceEmployeeHandler() {
        const { updateTimeslotReplaceEmployee, plannedMedicalExamination } = this.props;
        const { selectedEmployee } = this.state;

        if (this.isChangeEmployeeAllowed()) {
            updateTimeslotReplaceEmployee(
                selectedEmployee,
                plannedMedicalExamination,
            );
        }
    }
}

export default connect<IPrivateProps, IEditAppointmentDialogProps>({
    statePropsDeprecated: (state, publicProps) => {
        return {
            translator: getTranslatorDeprecated(state),
            updateTimeslotAsyncInfo: getUpdateTimeslotAsyncInfo(state),
            removeTimeslotAsyncInfo: getRemoveTimeslotAsyncInfo(state),
            caseManager: getCaseManager(state),
            validateEmployeeToPlanAsyncInfo: getValidateEmployeeToPlanAsyncInfo(state),
        };
    },
    dispatchPropsDeprecated: (dispatch, getState, publicProps) => {
        return {
            updateTimeslotReplaceEmployee: (
                employee: IEmployee,
                plannedMedicalExamination: IPlannedMedicalExamination,
            ) => {
                console.warn('Is change employee temporarily disabled:', TEMPORARILY_DISABLE_CHANGE_EMPLOYEE);

                // 🚨 This functionality was temporarily disabled 2 years ago.
                // In the meantime, the back-end functionality for moving a time
                // slot was moved from Kunstmaan rest api to Mensura Integration
                // layer BFF. This functionality was not included in the first
                // version of Future Proof Planning and is not possible with
                //  the current implementation of the move call.

                // const state = getState();
                // const plannedMedicalExamination =
                //     getPlannedMedicalExamination(state, publicProps.plannedMedicalExaminationId);

                // const examinationReason = getExaminationReasonByCode(
                //     state,
                //     plannedMedicalExamination.examinationReason.code,
                // );

                // const optionalToPlanInfoOfNewEmployee = employee &&
                //     getMedicalExaminationToPlanInfoByEmployeeId(
                //         state,
                //         {
                //             employeeId: employee.id,
                //             examinationReason: { id: examinationReason.id },
                //         },
                //     );

                // dispatch(triggerReplaceEmployeeUpdateTimeSlot({
                //     remarks: plannedMedicalExamination.remarks,
                //     employee: {
                //         id: employee.id,
                //         employeeId: employee.employeeId,
                //     },
                //     examinationReason: {
                //         id: examinationReason.id,
                //     },
                //     timeCellId: plannedMedicalExamination.timeSlotId,
                //     planningEntityId: plannedMedicalExamination.planningRequestId,
                //     company: {
                //         id: employee.company.id,
                //         companyCode: employee.company.companyCode,
                //     },
                //     planningRequestId: optionalToPlanInfoOfNewEmployee
                //         && optionalToPlanInfoOfNewEmployee.planningRequestId,
                // }));
            },
            cancelTimeSlot: (plannedMedicalExamination: IPlannedMedicalExamination) => {
                dispatch(removeTimeSlot({
                    timeSlotId: plannedMedicalExamination.timeSlotId,
                    activityId: plannedMedicalExamination.activityId,
                }));
            },
            triggerReplanWithSelectedExamination: (plannedMedicalExamination: IPlannedMedicalExamination) => {
                const state = getState();
                const examinationReason = getExaminationReasonByCode(
                    state,
                    plannedMedicalExamination.examinationReason.code,
                );

                dispatch(skipToPlanMedicalExaminationWizardStepActions.trigger({
                    wizardPayload: {
                        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.MOVE_PLANNING,
                        reason: examinationReason,
                        step: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_TIME,
                    },
                    entity: {
                        searchEmployee: {
                            autoSelected: true,
                            searchValue: '',
                            selectedEmployee: {
                                id: plannedMedicalExamination.employee.employment.id,
                                employeeId: plannedMedicalExamination.employee.id,
                                firstName: plannedMedicalExamination.employee.firstName,
                                name: plannedMedicalExamination.employee.lastName,
                            },
                        },
                        selectTime: undefined,
                        movePlanning: {
                            timeSlot: {
                                activityId: plannedMedicalExamination.activityId,
                                id: plannedMedicalExamination.timeSlotId,
                                start: plannedMedicalExamination.start,
                                end: plannedMedicalExamination.end,
                            },
                            planningRequestId: plannedMedicalExamination.planningRequestId,
                        },
                    },
                }));
            },
            navigateToConvocationStepOfPlanning: (
                newEmployee: IEmployee,
                plannedMedicalExamination: IPlannedMedicalExamination,
            ) => {
                const state = getState();
                const timeSlotId = getLastUpdatedTimeslotId(state);
                const examinationReason = getExaminationReasonByCode(
                    state,
                    plannedMedicalExamination.examinationReason.code,
                );

                dispatch(skipToPlanMedicalExaminationWizardStepActions.trigger({
                    wizardPayload: {
                        wizardType: PLAN_MEDICAL_EXAMINATION_WIZARD_TYPE.MOVE_PLANNING,
                        reason: examinationReason,
                        step: PLAN_MEDICAL_EXAMINATION_WIZARD_STEP_ID.MANUAL_NOTIFY,
                    },
                    entity: {
                        searchEmployee: {
                            autoSelected: true,
                            searchValue: '',
                            selectedEmployee: {
                                id: newEmployee.id,
                                employeeId: newEmployee.employeeId,
                                firstName: newEmployee.firstName,
                                name: newEmployee.name,
                            },
                        },
                        selectTime: {
                            timeSlotId,
                        },
                    },
                }));
            },
            clearError: (error) => {
                if (error) {
                    dispatch(clearErrors([error.id]));
                }
            },
            validateSelectedEmployee: (
                employeeToPlan: IEmployee,
                onValidationPassed: () => void,
                plannedMedicalExamination: IPlannedMedicalExamination,
            ) => {
                const state = getState();
                const examinationReason = getExaminationReasonByCode(
                    state,
                    plannedMedicalExamination.examinationReason.code,
                );

                dispatch(validateEmployeeToPlanActions.trigger({
                    employeeToPlan,
                    examinationReason,
                    onValidationPassed,
                }));
            },
        };
    },
})(EditAppointmentDialog);
