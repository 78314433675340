import { pathOr } from 'ramda';

import { IBufferzonePlannedMedicalExamination, IPlannedTimeSlot } from '../../models/interventions/medicalExaminations';
import { IPlannedMedicalExamination } from '../../models/interventions/medicalExaminations/planned';
import { IReservedMedicalExamination } from '../../models/interventions/medicalExaminations/reserved';

export const mapBffMedicalExaminationForPlannedMedicalExaminationExport = (
    plannedMedicalExamination: IBufferzonePlannedMedicalExamination,
    selectedMedicalExamination: IReservedMedicalExamination,
): IPlannedMedicalExamination => {
    const {
        examinationReason,
        planningRequestId,
        employee,
        timeSlotId,
        start,
        end,
        remarks,
        isEditable,
    } = plannedMedicalExamination;
    const {
        activity,
        location,
        doctor,
    } = selectedMedicalExamination;

    return {
        employee: {
            // We don't have access to the employee social security number & language
            // but that doesn't matter because this data is not exported
            socialSecurityNumber: '',
            language: '',
            id: pathOr(null, ['id'], employee),
            employment: {
                id: pathOr(null, ['employment', 'id'], employee),
                branch: {
                    code: pathOr('', ['employment', 'branch', 'code'], employee),
                    name: pathOr('', ['employment', 'branch', 'name'], employee),
                },
            },
            firstName: pathOr('', ['firstName'], employee),
            lastName: pathOr('', ['lastName'], employee),
        },
        activityId: pathOr(null, ['id'], activity),
        planningRequestId,
        examinationReason,
        timeSlotId,
        start,
        end,
        location: {
            name: location.name,
            address: {
                street: pathOr('', ['address', 'street'], location),
                houseNumber: pathOr('', ['address', 'houseNumber'], location),
                box: pathOr('', ['address', 'box'], location),
                zipCode: pathOr('', ['address', 'zipCode'], location),
                city: pathOr('', ['address', 'city'], location),
                countryCode: pathOr('', ['address', 'countryCode'], location),
            },
        },
        doctor,
        isEditable,
        remarks,
    };
};

export const mapBffMedicalExaminationToBufferzonePlannedTimeSlot = (
    reservedMedicalExamination: IReservedMedicalExamination,
    medicalExamination: IBufferzonePlannedMedicalExamination,
): IPlannedTimeSlot => {
    const { doctor, location, branch } = reservedMedicalExamination;

    return {
        ...medicalExamination,
        doctor,
        location,
        branch,
    };
};
