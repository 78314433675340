import React, { PureComponent } from 'react';
import { clone } from 'ramda';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IShouldRenderShowAllButtonProps,
    IRenderActionContentProps,
    IClientSideFilterOfListDataProps,
    IRenderSearchContentProps,
    IRenderFilterContentProps,
    ITransformToActiveFiltersProps,
    IOnSelectAllChangeProps,
    IRenderSelectAllTranslateProps,
    IIsSelectAllCheckboxSelected,
} from '../../../common/widget/MasterWithDetail/typings';
import ROUTE_KEYS from '../../../../routeKeys';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder, ListItemId } from '../../../../models/general/list';
import Translate from '../../../common/Translate';
import ListWithCheckboxSelect from '../../../common/list/ListWithCheckboxSelect';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import {
    getInvoiceDocumentAsyncInfo, getInvoiceDetailAsyncInfo,
    getInvoiceFullAsyncInfo,
    getPaidInvoices,
    getPaidInvoicesAsyncInfo,
} from '../../../../redux/invoice/selectors';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { IInvoice } from '../../../../models/admin/invoices';
import { formatPrice } from '../../../../utils/formatting/formatPrice';
import { DEFAULT_PAID_INVOICES_FILTERS } from '../../../../api/admin/invoices.api';
import { connect } from '../../../index';
import {
    dismissInvoiceNotFoundDialog,
} from '../../../../redux/invoice/actions';
import { AsyncStatus } from '../../../../models/general/redux';
import Dialog from '../../../common/modals/Dialog/index';
import Loader from '../../../common/waiting/Loader/index';
import Button from '../../../common/buttons/Button';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';
import TextInput from '../../../common/input/TextInput';
import InvoiceActions from '../shared/InvoiceActions';
import { IInvoiceColumnNames, IFilterValues, IPrivateProps, IInvoiceItemData } from '../shared/typings';
import InvoiceHeaderActions from '../shared/InvoiceHeaderActions';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import { MAX_NR_OF_DOCUMENT_DOWNLOADS } from '../../../../config/general.config';
import sortListItems from '../../../../utils/list/sortListItems';

const BASE_NAME = 'paid-invoices';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;
const MAX_NR_OF_INVOICE_DOWNLOADS = MAX_NR_OF_DOCUMENT_DOWNLOADS;
const INVOICES_TRANS_PREFIX = 'administration.invoices';
const TRANSLATION_PREFIX = `${INVOICES_TRANS_PREFIX}.open`;

interface IColumnNames extends IInvoiceColumnNames { }

type TInvoicesListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;
type TInvoiceListItem = ListItem<IColumnNames, ListItemId, IInvoiceItemData>;

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'invoiceDate',
    sortOrder: SortOrder.Descending,
};

const COLUMNS: ListColumns<IColumnNames> = {
    invoiceDate: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.invoice_date`} />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.invoiceDateSort,
        percentWidth: 16,
    },
    invoiceDateSort: {
        hide: true,
        percentWidth: null,
    },
    invoiceNumber: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.number`} />,
        sortable: true,
        sortType: SortType.Number,
        percentWidth: 13,
    },
    companyName: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.company`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    companyCode: {
        hide: true,
        percentWidth: null,
    },
    type: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.type`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 11,
    },
    description: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.description`} />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    amount: {
        label: <Translate msg={`${TRANSLATION_PREFIX}.columns.amount`} />,
        sortable: true,
        sortType: SortType.Number,
        percentWidth: 12,
        align: 'right',
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.amountSort,
    },
    amountSort: {
        hide: true,
        percentWidth: null,
    },
    actions: {
        sortable: false,
        percentWidth: 8,
    },
};

interface IComponentState {
    selectedInvoiceIds: string[];
    sortedColumn: ISortedColumn<IColumnNames>;
}

class PaidInvoices extends React.Component<IPrivateProps, IComponentState> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            selectedInvoiceIds: [],
            sortedColumn: INITIAL_SORT,
        };

        this.setSortedColumn = this.setSortedColumn.bind(this);
        this.setSelectedInvoiceIds = this.setSelectedInvoiceIds.bind(this);
        this.isSelectAllCheckboxSelected = this.isSelectAllCheckboxSelected.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);
        this.renderSelectAllTranslate = this.renderSelectAllTranslate.bind(this);
    }

    render () {
        return (
            <MasterWithDetail
                baseName={BASE_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    routeKey: ROUTE_KEYS.R_INVOICES_PAID,
                    asyncInfoSelector: getPaidInvoicesAsyncInfo,
                    dataSelector: getPaidInvoices,
                    transformData: mapInvoicesToListItems,
                    transformFilterValuesToActiveFilters,
                    renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <InvoicesList
                            {...renderProps}
                            {...this.props}
                            {...this.state}
                            setSortedColumn={this.setSortedColumn}
                            setSelectedInvoiceIds={this.setSelectedInvoiceIds}
                        />,
                    clientSideSearchOfListData: {
                        searchFilterName: 'search',
                        columnsConfig: COLUMNS,
                    },
                    clientSideFilterOfListData,
                    filterValidationSchema: startEndDateSchema,
                }}
                headerConfig={{
                    selectAllCheckbox: {
                        renderTranslateComponent: this.renderSelectAllTranslate,
                        isSelected: this.isSelectAllCheckboxSelected,
                        onSelectionChange: this.onSelectAllChange,
                    },
                    renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                        <SearchContent {...renderProps} />,
                    renderFilterContent:
                        (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                            <FilterContent {...renderProps} />,
                    renderActionContent:
                        (renderProps: IPrivateProps & IRenderActionContentProps<ListItem<IColumnNames>[]>) =>
                            <InvoiceHeaderActions {...renderProps} {...this.props} {...this.state} />,
                    exportButton: {
                        baseFilename: 'invoices-paid',
                        listItemIdExtractor: toListId,
                        mapListRowForExport,
                    },
                }}
                footerConfig={{
                    shouldRenderShowAllButton,
                }}
            />
        );
    }

    private setSortedColumn(sortedColumn: ISortedColumn<IColumnNames>) {
        this.setState({
            sortedColumn,
        });
    }

    private setSelectedInvoiceIds(selectedInvoiceIds: string[]) {
        this.setState({
            selectedInvoiceIds,
        });
    }

    private isSelectAllCheckboxSelected(
        isSelectedProps: IIsSelectAllCheckboxSelected<ListItem<IColumnNames>[], IFilterValues>,
    ) {
        const { selectedInvoiceIds } = this.state;
        const {
            masterData: clientSideFilteredlistItems,
            filterValues,
        } = isSelectedProps;

        if (selectedInvoiceIds.length <= 0) {
            return false;
        }

        const maxNrSelected = getMaxNrSelected(filterValues);
        if (clientSideFilteredlistItems['length'] < maxNrSelected) {
            return selectedInvoiceIds.length === clientSideFilteredlistItems['length'];
        }

        return selectedInvoiceIds.length === maxNrSelected;
    }

    private onSelectAllChange(selectAllProps: IOnSelectAllChangeProps<ListItem<IColumnNames>[], IFilterValues>) {
        const {
            isSelected,
            masterData: clientSideFilteredlistItems,
            filterValues,
        } = selectAllProps;

        const maxNrSelected = filterValues.isShowAll
            ? MAX_NR_OF_INVOICE_DOWNLOADS
            : Math.min(MAX_NR_OF_INVOICE_DOWNLOADS, DEFAULT_NR_OF_RECORDS_TO_SHOW);

        const selectedInvoices = isSelected
            ? clientSideFilteredlistItems.length > maxNrSelected
                // eslint-disable-next-line max-len
                ? sortListItems(clientSideFilteredlistItems, this.state.sortedColumn, COLUMNS[this.state.sortedColumn.name])
                    .slice(0, maxNrSelected)
                : clientSideFilteredlistItems
            : [];

        this.setSelectedInvoiceIds(selectedInvoices.map((listItem) => listItem.id as string));
    }

    private renderSelectAllTranslate(props: IRenderSelectAllTranslateProps) {
        const {
            masterData: clientSideFilteredlistItems,
        } = props;
        if (clientSideFilteredlistItems['length'] <= MAX_NR_OF_INVOICE_DOWNLOADS) {
            return (
                <Translate msg={`${INVOICES_TRANS_PREFIX}.actions.select_all`} />
            );
        }

        return (
            <Translate
                msg={`${INVOICES_TRANS_PREFIX}.actions.select_all_with_max`}
                placeholders={{
                    amount: MAX_NR_OF_INVOICE_DOWNLOADS,
                }}
            />
        );
    }
}

function toListId(invoice: IInvoice) {
    return invoice.invoiceId;
}

function mapInvoicesToListItems(masterData: IInvoice[]): TInvoiceListItem[] {
    return masterData
        .map((invoice) => ({
            id: toListId(invoice),
            columns: {
                invoiceDate: formatDateInLongFormat(invoice.invoiceDate),
                invoiceDateSort: invoice.invoiceDate,
                invoiceNumber: invoice.invoiceNumber,
                description: invoice.invoiceTypeDescription,
                type: invoice.invoiceDocumentTypeDescription,
                companyName: invoice.company.name,
                companyCode: invoice.company.companyCode,
                amount: formatPrice(invoice.invoiceTotal),
                amountSort: invoice.invoiceTotal,
                actions: null,
            },
            data: {
                doneManually: invoice.doneManually,
            },
        }));
}

function mapListRowForExport(invoice: IInvoice) {
    const {
        company: { name },
        company: { companyCode },
        dueDate,
        invoiceDate,
        invoiceDocumentTypeDescription,
        invoiceNumber,
        invoiceReference,
        invoiceTotal,
        invoiceTypeDescription,
        paid,
        payDate,
        paymentCodeDescription,
        period,
    } = invoice;

    return {
        company: {
            companyCode,
            name,
        },
        dueDate,
        invoiceDate,
        invoiceDocumentTypeDescription,
        invoiceNumber,
        invoiceReference,
        invoiceTotal,
        invoiceTypeDescription,
        paid,
        payDate,
        paymentCodeDescription,
        period,
    };
}

interface ISelectInvoicesProps extends IComponentState {
    setSortedColumn: (sortedColumn: ISortedColumn<IColumnNames>) => void;
    setSelectedInvoiceIds: (selectedIds: string[]) => void;
}

class InvoicesList extends PureComponent<TInvoicesListProps & IPrivateProps & ISelectInvoicesProps> {
    private columns = clone(COLUMNS);

    constructor(props: TInvoicesListProps & IPrivateProps & ISelectInvoicesProps) {
        super(props);

        this.columns.actions.render = (listItem: TInvoiceListItem) => {
            return (
                <InvoiceActions
                    listItem={listItem}
                />
            );
        };
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            selectedInvoiceIds,
            setSelectedInvoiceIds,
            setSortedColumn,
            filterValues,
            downloadBusy,
            dismissInvoiceNotFoundDialog,
            footer,
            downloadError,
        } = this.props;

        return (
            <>
                <ListWithCheckboxSelect
                    name={BASE_NAME}
                    withSorting={true}
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    selectedItemIds={selectedInvoiceIds}
                    onItemSelected={setSelectedInvoiceIds}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                    maxSelectedItems={MAX_NR_OF_INVOICE_DOWNLOADS}
                    initialSort={INITIAL_SORT}
                    onColumnSortChanged={setSortedColumn}
                />
                <Dialog
                    show={downloadError}
                    onCloseIntent={() => dismissInvoiceNotFoundDialog()}
                    header={`${INVOICES_TRANS_PREFIX}.dialog.title`}
                    type="error"
                >
                    <p>
                        <Translate msg={`${INVOICES_TRANS_PREFIX}.dialog.text`} />
                    </p>
                    <div className="Dialog__buttons">
                        <Button
                            id="close-dialog"
                            typeName="secondary"
                            onClick={() => dismissInvoiceNotFoundDialog()}
                        >
                            <Translate msg={`${INVOICES_TRANS_PREFIX}.dialog.button`} />
                        </Button>
                    </div>
                </Dialog>
                <Loader show={downloadBusy} positionFixed={true} />
            </>
        );
    }
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.documentTypes)) {
                return true;
            }

            const documentTypes = separateStringList(filterValues.documentTypes);
            return documentTypes.includes(listItem.columns.type.toString());
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.seatCodes)) {
                return true;
            }

            const seatCodes = separateStringList(filterValues.seatCodes);
            return seatCodes.includes(listItem.columns.companyCode as string);
        })
        .filter((listItem) => {
            if (!isFilterSet(filterValues.documentDescriptions)) {
                return true;
            }

            const documentDescriptions = separateStringList(filterValues.documentDescriptions);
            return documentDescriptions.includes(listItem.columns.description.toString());
        });
}

export function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator(`${TRANSLATION_PREFIX}.filter.search`)}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg={`${TRANSLATION_PREFIX}.filter.search`} />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleDocumentTypes = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'type',
        'type',
    );

    const possibleDocumentDescriptions = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'description',
        'description',
    );

    const possibleSeats = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'companyCode',
        'companyName',
    );
    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                formRenderProps={formRenderProps}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="seat"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.seat`}
                possibleFilterItems={possibleSeats}
                actualFilterValue={formRenderProps.values.seatCodes}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'seatCodes',
                    newFilterValue,
                )}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="type"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.document_type`}
                possibleFilterItems={possibleDocumentTypes}
                actualFilterValue={formRenderProps.values.documentTypes}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'documentTypes',
                    newFilterValue,
                )}
            />
            <CheckboxesOrTypeaheadFilter
                filterName="description"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.document_description`}
                possibleFilterItems={possibleDocumentDescriptions}
                actualFilterValue={formRenderProps.values.documentDescriptions}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'documentDescriptions',
                    newFilterValue,
                )}
            />
        </div>
    );
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_PAID_INVOICES_FILTERS,
        isShowAll,
    } : DEFAULT_PAID_INVOICES_FILTERS;
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: `${TRANSLATION_PREFIX}.active_filter`,
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_PAID_INVOICES_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_PAID_INVOICES_FILTERS.startDate,
            },
            seatCodes: {
                show: true,
                translationKeySuffixOverride: 'seat',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'companyName',
                        searchColumnName: 'companyCode',
                    },
                },
            },
            documentDescriptions: {
                show: true,
                translationKeySuffixOverride: 'document_description',
                multiple: {
                    enable: true,
                },
            },
            documentTypes: {
                show: true,
                translationKeySuffixOverride: 'document_type',
                multiple: {
                    enable: true,
                },
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const downloadBusy = getInvoiceDocumentAsyncInfo(state).status === AsyncStatus.Busy
            || getInvoiceDetailAsyncInfo(state).status === AsyncStatus.Busy
            || getInvoiceFullAsyncInfo(state).status === AsyncStatus.Busy;

        const downloadError = getInvoiceDocumentAsyncInfo(state).status === AsyncStatus.Error
            || getInvoiceDetailAsyncInfo(state).status === AsyncStatus.Error
            || getInvoiceFullAsyncInfo(state).status === AsyncStatus.Error;

        return {
            downloadBusy,
            downloadError,
            invoiceDetailAsyncInfo: getInvoiceDetailAsyncInfo(state),
            invoiceFullAsyncInfo: getInvoiceFullAsyncInfo(state),
        };
    },
    dispatchProps: (dispatch) => ({
        dismissInvoiceNotFoundDialog: () => {
            dispatch(dismissInvoiceNotFoundDialog());
        },
    }),
})(PaidInvoices);

function getMaxNrSelected(filterValues: IFilterValues) {
    return filterValues.isShowAll
        ? MAX_NR_OF_INVOICE_DOWNLOADS
        : Math.min(MAX_NR_OF_INVOICE_DOWNLOADS, DEFAULT_NR_OF_RECORDS_TO_SHOW);
}
